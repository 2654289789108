const url = "https://patientrebateonline.cs.east.us.mckesson.com/api/";
export const variables = {
  card: url + 'card',
  lock: url + 'lock',
  lockStatus: url + 'patientLockStatus',
  name: url + 'name',
  online: url + 'online',
  validate: url + 'validate',
  eventLookup: url + 'patients/event',
  deleteFile: url + 'mail/delete/',
  mail: url + 'mail',
  dataDomainScriptId: '0191d5e3-fa5f-7b25-b5c6-cafaeb5828cf',
  reCaptcha: "6LfaWrEZAAAAAKfuaMVfQ7ZFgJ1Rsi9evhoPmKdg",
  x1: 309593752,
  x2: 310662776,
  x3: 8,
  y1: 1131376737,
  y2: 2035443042,
  y3: 1635017984,
  y4: 11,
  selfServiceUrl: "https://copayhelp.mckesson.com/selfservice/",
  privacyPolicyLink: "https://www.activatethecard.com/copayselfservice/privacypolicy.html#"
};
